import FocusTrap from 'focus-trap-react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t, translate } from '../utils/ReactSwitchLangWrapper';
import style from '../styles/Popup.module.scss';
import Button from './Button';

function PopUp({ setShowPopup, ampEvent, children, className = '' }) {
  return (
    <FocusTrap>
      <div role="dialog" className={style.modal}>
        <div className={`${style.modal_content} ${className}`}>
          <Button
            title={t('ButtonDescription_ClosePopup')}
            ampEvent={ampEvent}
            ampEventProps={{ Button: 'X' }}
            className={style.close}
            onClick={() => setShowPopup(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div className={style.modalTxt}>
            {children}
          </div>
        </div>
      </div>
    </FocusTrap>
  );
}

export default translate(PopUp);
