import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { t } from '../../utils/ReactSwitchLangWrapper';
import { ExternalLink } from '../Link';
import { OAUTH_LINKS, OAUTH_LOADING_STATE } from '../../utils/Constants';
import PopUp from '../PopUp';
import style from '../../styles/AuthForm.module.scss';
import popupStyles from '../../styles/Popup.module.scss';
import { events } from '../../utils/Amplitude';

function ShopifyPopup({ show, setShow, loading, setLoading }) {
  return (
    show && (
      <PopUp
        ampEvent={events.USER_CLOSED_SHOPIFY_LOGIN_POPUP}
        setShowPopup={setShow}
      >
        <p>{t('Login_Shopify_Popup_Text_Part1')}</p>
        <p>{t('Login_Shopify_Popup_Text_Part2')}</p>
        <p>{t('Login_Shopify_Popup_Text_Part3')}</p>
        <div className={popupStyles.buttonCont}>
          <ExternalLink
            href={OAUTH_LINKS.Shopify}
            className={`${style.linkBtn}`}
            ampEvent={events.USER_CLICKED_CONTINUE_TO_SHOPIFY_BUTTON}
            target={undefined}
            onClick={() => setLoading(OAUTH_LOADING_STATE.SHOPIFY)}
            aria-label={t('LinkDescription_Login_Shopify_Popup_Btn')}
          >
            {loading === OAUTH_LOADING_STATE.SHOPIFY ? (
              <FontAwesomeIcon icon={faSpinnerThird} spin className="fastSpin" size="1x" />
            ) : (
              t('Login_Shopify_Popup_Btn')
            )}
          </ExternalLink>
        </div>
      </PopUp>
    )
  );
}

export default ShopifyPopup;
